import { enumToArray } from '../utils/enum-to-array';

/** Session type. */
export enum SessionType {
	Consultation = 'consultation',
	Mentorship = 'mentorship',
}

export namespace SessionType {

	const TO_READABLE: Readonly<Record<SessionType, string>> = {
		consultation: 'Consultation',
		mentorship: 'Mentorship',
	};

	/**
	 * Maps session type to readable value.
	 * @param type Session type.
	 */
	export function toReadable(type: string): string {
		return isValidSessionType(type) ? TO_READABLE[type] : type;
	}

	/**
	 * Checks whether provided value is a valid session type.
	 * @param value Value to check.
	 */
	export function isValidSessionType(value: unknown): value is SessionType {
		return enumToArray(SessionType).includes(value as SessionType);
	}
}
